import React from 'react'
import { Menu } from 'antd'
import { NavLink } from 'react-router-dom'
import {
  UserOutlined,
  DashboardOutlined,
  CreditCardOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons'

export default function SideBarMenu({ pathname, setVisibleDrawer = () => {} }) {
  let selectedItem = pathname.split('/')[1]

  return (
    <Menu
      theme='dark'
      mode='inline'
      selectedKeys={[selectedItem || 'dashboard']}
    >
      <Menu.Item key='dashboard' onClick={() => setVisibleDrawer(false)}>
        <NavLink to='/' className='nav-text'>
          <DashboardOutlined />
          <span>Dashboard</span>
        </NavLink>
      </Menu.Item>
      <Menu.Item key='users' onClick={() => setVisibleDrawer(false)}>
        <NavLink to='/users' className='nav-text'>
          <UserOutlined />
          <span>User</span>
        </NavLink>
      </Menu.Item>
      <Menu.Item key='posts' onClick={() => setVisibleDrawer(false)}>
        <NavLink to='/posts' className='nav-text'>
          <CreditCardOutlined />
          <span>Post</span>
        </NavLink>
      </Menu.Item>
      <Menu.Item key='feedBack' onClick={() => setVisibleDrawer(false)}>
        <NavLink to='/feedBack' className='nav-text'>
          <UsergroupAddOutlined />
          <span>Feed Back</span>
        </NavLink>
      </Menu.Item>
    </Menu>
  )
}
