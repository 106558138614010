import Axios from 'axios'

export async function handleDataGet(path) {
  const response = await Axios.get(
    `${process.env.REACT_APP_SERVER_GRAPH_URL}/${path}`
  )

  return response
}

export async function handleUpdateData(path, data) {
  const response = await Axios.post(
    `${process.env.REACT_APP_SERVER_GRAPH_URL}/${path}`,
    data
  )

  return response
}

export async function sendPostByAdmin(path, data) {
  console.log({ path, data })
  const response = await Axios.post(
    `${process.env.REACT_APP_SERVER_GRAPH_URL}/${path}`,
    data
  )

  return response
}
