import React from 'react'
import { Helmet } from 'react-helmet'

export default function ({ title, description = '' }) {
  return (
    <Helmet>
      <meta charSet='utf-8' />
      <title>
        {title}
        {description && ` - ${description}`} | ChatBot
      </title>
      {description && <meta name='description' content={description} />}
    </Helmet>
  )
}
