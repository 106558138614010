import { Button, DatePicker, Input, PageHeader, Space, Table } from 'antd'
import CustomMeta from 'components/CustomMeta'
import Page404 from 'components/Page404'
import moment from 'moment'
import _isEmpty from 'lodash/isEmpty'
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { handleDataGet } from 'utils/function'
import { SearchOutlined } from '@ant-design/icons'

const { RangePicker } = DatePicker

const Users = () => {
  const [skip, setSkip] = useState(0)
  const [limit, setLimit] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [filters, setFilters] = useState({})

  const { data, isLoading, isError } = useQuery(
    ['UsersData', filters, limit, skip],
    () =>
      handleDataGet(
        `subscribers?page=${skip}&size=${limit}&filter=${JSON.stringify(
          filters
        )}`
      ),
    { keepPreviousData: true }
  )

  if (isError) return <Page404 />

  function handleSearch(value, confirm, filedName) {
    value = typeof value === 'object' ? value[0] : value
    confirm()
    const tempFilters = JSON.parse(JSON.stringify(filters))
    tempFilters[filedName] = value
    setFilters(tempFilters)
    setCurrentPage(1)
  }

  function handleReset(clearFilters, filedName) {
    clearFilters()
    const tempFilters = JSON.parse(JSON.stringify(filters))
    tempFilters[filedName] = ''
    setFilters(tempFilters)
    setCurrentPage(1)
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          autoFocus={true}
          value={selectedKeys[0]}
          placeholder={`Search ${dataIndex}`}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys[0], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters, dataIndex)}
            size='small'
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
  })

  const getColumnDateProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      const from = `${dataIndex}From`
      const to = `${dataIndex}To`
      let defaultValue = []
      if (!_isEmpty(filters[from])) {
        defaultValue = [moment(filters[from]), moment(filters[to])]
      }
      return (
        <div style={{ padding: 8 }}>
          <RangePicker
            format={'DD-MMM-YYYY'}
            defaultValue={defaultValue}
            allowClear={true}
            onChange={(e) => {
              let tempFilters
              if (e) {
                confirm()
                tempFilters = JSON.parse(JSON.stringify(filters))
                tempFilters[from] = e[0]
                tempFilters[to] = e[1]
              } else {
                clearFilters()
                tempFilters = JSON.parse(JSON.stringify(filters))
                delete tempFilters[from]
                delete tempFilters[to]
              }
              setFilters(tempFilters)
              setCurrentPage(1)
            }}
          />
        </div>
      )
    },
  })

  const columns = [
    {
      title: 'Space Name',
      key: 'space_name',
      render: (_, record) => record?.space_name,
      ...getColumnSearchProps('space_name'),
    },
    {
      title: 'Space Type',
      key: 'space_type',
      render: (_, record) => record?.space_type,
      ...getColumnSearchProps('space_type'),
    },
    {
      title: 'User Name',
      key: 'added_by_name',
      render: (_, record) => record?.added_by_name,
      ...getColumnSearchProps('added_by_name'),
    },
    {
      title: 'User Email',
      key: 'added_by_email',
      render: (_, record) => record?.added_by_email,
      ...getColumnSearchProps('added_by_email'),
    },
    {
      title: 'Platform',
      key: 'action',
      render: (_, record) => record?.platform,
      ...getColumnSearchProps('platform'),
    },
    {
      title: 'Created At',
      key: 'createdAt',
      render: (_, record) => moment(record?.createdAt).format('DD-MMM-YYYY'),
      ...getColumnDateProps('createdAt'),
    },
  ]

  function handlePagination(page) {
    setSkip((page - 1) * limit)
    setCurrentPage(page)
  }

  function handlePageSizeChange(current, size) {
    setLimit(size)
  }

  return (
    <>
      <CustomMeta title={'Users'} description='' />
      <PageHeader className='box' title='Users' />
      <Table
        columns={columns}
        loading={isLoading}
        dataSource={!isLoading && data?.data?.rows}
        pagination={{
          pageSize: limit,
          total: !isLoading && data?.data?.count,
          onChange: handlePagination,
          current: currentPage,
          onShowSizeChange: handlePageSizeChange,
          showSizeChanger: false,
        }}
      />
    </>
  )
}

export default Users
