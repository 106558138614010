import React, { useState } from 'react'
import { Button, Col, Form, Modal, Row, Select } from 'antd'
import { useMutation, useQuery } from 'react-query'
import { handleDataGet, sendPostByAdmin } from 'utils/function'
import Loader from 'components/loaders/Loader'
import Page404 from 'components/Page404'

const { Option } = Select

export default function CreatePostModel({
  openCreatePostModel,
  closeModel,
  feedBackId,
  setOpenCreatePostModel,
}) {
  const [spaceId, setSpaceId] = useState()
  const {
    data: users,
    isLoading,
    isError,
  } = useQuery(
    ['UsersData'],
    () => handleDataGet(`subscribers?size=10000&page=0`),
    { keepPreviousData: true }
  )

  const { isLoading: sendPostLoading, mutate } = useMutation((data) =>
    sendPostByAdmin(`sendpost`, data)
  )

  if (isLoading) return <Loader />
  if (isError) return <Page404 />

  const onFinish = () => {
    let values = { id: feedBackId, space_id: spaceId }

    mutate(values)
    if (!sendPostLoading) {
      setOpenCreatePostModel(false)
    }
  }
  return (
    <>
      <Modal
        title='Reply Feed Back'
        visible={openCreatePostModel}
        onCancel={closeModel}
        footer={null}
      >
        <div>
          <Row gutter={16}>
            <Col className='gutter-row' span={3}></Col>

            <Col className='gutter-row' span={18}>
              <Form onFinish={onFinish}>
                <Form.Item
                  label='User'
                  name='space_id'
                  wrapperCol={{
                    offset: 8,
                    span: 16,
                  }}
                >
                  <Select
                    mode='multiple'
                    showSearch
                    allowClear
                    placeholder='Select Commission User'
                    optionFilterProp='children'
                    autoComplete='something-new'
                    onChange={(e) => setSpaceId(e)}
                  >
                    {users?.data?.rows &&
                      users?.data?.rows.map((user) => (
                        <Option key={user.space_id} value={user?.space_id}>
                          {user?.space_name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
                <Form.Item>
                  <Button
                    type='primary'
                    htmlType='submit'
                    loading={sendPostLoading}
                  >
                    Send Post
                  </Button>
                </Form.Item>
              </Form>
            </Col>
            <Col className='gutter-row' span={3}></Col>
          </Row>
        </div>
      </Modal>
    </>
  )
}
