import { Col, Row } from 'antd'
import Loader from 'components/loaders/Loader'
import Page404 from 'components/Page404'
import React from 'react'
import { useQuery } from 'react-query'
import { handleDataGet } from 'utils/function'
import '../Dashboard/index.css'
import {
  HomeOutlined,
  UserOutlined,
  BookOutlined,
  SendOutlined,
  CalendarOutlined,
} from '@ant-design/icons'

const Dashboard = () => {
  const { data, isLoading, isError } = useQuery('dashboardData', () =>
    handleDataGet('dashboard/counters')
  )
  if (isLoading) return <Loader />
  if (isError) return <Page404 />
  return (
    <>
      <div>
        <Row className='total-viewer-numbers' gutter={16}>
          <Col xs={24} sm={24} md={4} xl={4}>
            <div className='dashboard_data_box_first'>
              <div className='total_heading_name'>Total Active Room:</div>
              <div className='inner_amount_icon_div'>
                <div className='total_heading_number'>
                  {data?.data?.totalActiveRoom}
                </div>
                <div>
                  <HomeOutlined style={{ fontSize: '40px' }} />
                </div>
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={4} xl={4}>
            <div className='dashboard_data_box_second'>
              <div className='total_heading_name'>Total Active User:</div>
              <div className='inner_amount_icon_div'>
                <div className='total_heading_number'>
                  {data?.data?.totalActiveUser}
                </div>
                <div>
                  <UserOutlined style={{ fontSize: '40px' }} />
                </div>
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={4} xl={4}>
            <div className='dashboard_data_box_third'>
              <div className='total_heading_name'>Total News:</div>
              <div className='inner_amount_icon_div'>
                <div className='total_heading_number'>
                  {data?.data?.totalNews}
                </div>
                <div>
                  <BookOutlined style={{ fontSize: '40px' }} />
                </div>
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={4} xl={4}>
            <div className='dashboard_data_box_forth'>
              <div className='total_heading_name'>Total News Send:</div>
              <div className='inner_amount_icon_div'>
                <div className='total_heading_number'>
                  {data?.data?.totalNewsSend}
                </div>
                <div>
                  <SendOutlined style={{ fontSize: '40px' }} />
                </div>
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={4} xl={4}>
            <div className='dashboard_data_box_fifth'>
              <div className='total_heading_name'>Yester Day News:</div>
              <div className='inner_amount_icon_div'>
                <div className='total_heading_number'>
                  {data?.data?.yesterDayNews}
                </div>
                <div>
                  <CalendarOutlined style={{ fontSize: '40px' }} />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default Dashboard
