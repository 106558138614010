import React, { useState, Fragment, useContext } from 'react'
import { Layout, Avatar, Popover } from 'antd'
import _get from 'lodash/get'
import {
  MenuOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  // EyeOutlined,
  PoweroffOutlined,
} from '@ant-design/icons'
import { AppContext } from 'AppContext'
import history from 'CustomHistory'
import { getFirstLetter, getName } from 'utils/User'

// import history from 'CustomHistory'

const { Header } = Layout

export default function ({ collapsed, toggle, drawerToggle }) {
  const color = '#7265e6'
  // const [visible, setVisible] = useState(false)
  const [visibleMenu, setVisibleMenu] = useState(false)

  const {
    state: { currentUser },
  } = useContext(AppContext)

  const showFilters = _get(history, 'location.pathname') === '/'

  const userMenuOptions = (
    <ul className='gx-user-popover'>
      {/* <li
        onClick={() => {
          setVisibleMenu(false)
          history.push('/profile')
        }}
      >
        <i className='icon icon-avatar -flex-column-reverse menu-profile'></i>{' '}
        Profile
      </li>
      <li
        onClick={() => {
          setVisibleMenu(false)
          handleVisible()
        }}
      >
        <EyeOutlined /> Change Password
      </li> */}
      <li onClick={() => (window.location.href = '/logout')}>
        <PoweroffOutlined /> Logout
      </li>
    </ul>
  )

  // function handleVisible() {
  //   setVisible(!visible)
  // }

  return (
    <Fragment>
      <Header className='site-layout-background'>
        {React.createElement(
          window.innerWidth >= 991
            ? collapsed
              ? MenuUnfoldOutlined
              : MenuFoldOutlined
            : MenuOutlined,
          {
            className: 'trigger',
            onClick: () =>
              window.innerWidth >= 991 ? toggle() : drawerToggle(),
          }
        )}
        <div className='gx-flex-row gx-align-items-center gx-avatar-row'>
          <Popover
            placement='bottomRight'
            onVisibleChange={() => setVisibleMenu(!visibleMenu)}
            visible={visibleMenu}
            content={userMenuOptions}
            trigger='click'
          >
            <div>
              <Avatar
                style={{ backgroundColor: color, verticalAlign: 'middle' }}
                size='default'
                className='gx-pointer'
              >
                {getFirstLetter(currentUser)}
              </Avatar>
              <span className='gx-avatar-name'>
                <span
                  className={`header-name-ml ${
                    showFilters ? 'header-name' : ''
                  }`}
                >
                  {getName(currentUser)}
                </span>
                <i className='icon icon-chevron-down gx-fs-xxs gx-ml-2' />
              </span>
              {/* <div className='gx-avatar-role'><span className='gx-avatar-name'>{currentUser.role.split('_').join(' ').toLowerCase()}</span></div> */}
            </div>
          </Popover>
        </div>
      </Header>
    </Fragment>
  )
}
