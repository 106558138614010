import React, { useState } from 'react'
import { configure, HotKeys } from 'react-hotkeys'
import { Layout, Drawer } from 'antd'
import { NavLink } from 'react-router-dom'
import _get from 'lodash/get'

import { version } from '../../package.json'
import CustomHeader from './Header'
import MainRoutes from 'MainRoutes'
import CustomSider from './Sider'
import Menu from './Menu'
import './layout.css'

const { Content } = Layout

configure({
  ignoreEventsCondition: (event) => {
    return false
  },
})

const keyMap = {
  SEARCH_FIRST_NAME_NODE: ['ctrl+shift+f', 'cmd+shift+f'],
  SEARCH_LAST_NAME_NODE: ['ctrl+shift+l', 'cmd+shift+l'],
}

export default function LayoutWrapper(props) {
  const {
    location: { pathname },
  } = props
  const [collapsed, setCollapsed] = useState(false)
  const [visibleDrawer, setVisibleDrawer] = useState(false)

  function searchFirstName() {
    if (
      (pathname === '/users' || pathname === '/clients') &&
      _get(
        document.getElementsByClassName(
          'ant-table-filter-trigger ant-dropdown-trigger'
        ),
        '[0]'
      )
    ) {
      document
        .getElementsByClassName(
          'ant-table-filter-trigger ant-dropdown-trigger'
        )[0]
        .click()
    }
  }

  function searchLastName() {
    if (
      (pathname === '/users' || pathname === '/clients') &&
      _get(
        document.getElementsByClassName(
          'ant-table-filter-trigger ant-dropdown-trigger'
        ),
        '[1]'
      )
    ) {
      document
        .getElementsByClassName(
          'ant-table-filter-trigger ant-dropdown-trigger'
        )[1]
        .click()
    }
  }

  const handlers = {
    SEARCH_FIRST_NAME_NODE: searchFirstName,
    SEARCH_LAST_NAME_NODE: searchLastName,
  }

  return (
    <HotKeys keyMap={keyMap} handlers={handlers}>
      <Layout>
        <CustomSider collapsed={collapsed} pathname={pathname} />
        <Layout className={`site-layout with-alert`}>
          <CustomHeader
            collapsed={collapsed}
            toggle={() => setCollapsed(!collapsed)}
            drawerToggle={() => setVisibleDrawer(!visibleDrawer)}
          />
          <Drawer
            placement='left'
            closable={false}
            visible={visibleDrawer}
            className='layout-drawer'
            onClose={() => setVisibleDrawer(!visibleDrawer)}
            title={
              <NavLink to='/' className='nav-text'>
                <img alt='' src={''} />
              </NavLink>
            }
          >
            <div className='menu-wrapper'>
              <Menu pathname={pathname} setVisibleDrawer={setVisibleDrawer} />
            </div>
            <p className='version-print'>V {version}</p>
          </Drawer>

          <Content>
            <div className='content-wrapper'>
              <MainRoutes />
            </div>
          </Content>
        </Layout>
      </Layout>
    </HotKeys>
  )
}
