import React, { useState } from 'react'
import Page404 from 'components/Page404'
import { handleDataGet } from 'utils/function'
import { useQuery } from 'react-query'
import { Button, DatePicker, Input, PageHeader, Space, Table } from 'antd'
import moment from 'moment'
import _isEmpty from 'lodash/isEmpty'
import { Link } from 'react-router-dom'
import { SearchOutlined } from '@ant-design/icons'
import CustomMeta from 'components/CustomMeta'
import CreatePostModel from 'components/models/CreatePostModel'

const { RangePicker } = DatePicker

export default function Posts() {
  const [skip, setSkip] = useState(0)
  const [limit, setLimit] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [openCreatePostModel, setOpenCreatePostModel] = useState(false)
  const [feedBackId, setFeedBackId] = useState(0)
  const [filters, setFilters] = useState({})

  const { data, isLoading, isError } = useQuery(
    ['PostData', filters, limit, skip],
    () =>
      handleDataGet(
        `posts?page=${skip}&size=${limit}&filter=${JSON.stringify(filters)}`
      ),
    { keepPreviousData: true }
  )

  const openModel = (id) => {
    setOpenCreatePostModel(true)
    setFeedBackId(id)
  }
  const closeModel = () => {
    setOpenCreatePostModel(false)
  }

  if (isError) return <Page404 />

  const renderAction = (record) => {
    const { id } = record
    return (
      <div className='action-icons'>
        <Button
          key='1'
          type='primary'
          onClick={() => openModel(id)}
          loading={isLoading}
        >
          Send Post By Admin
        </Button>
      </div>
    )
  }

  function handleSearch(value, confirm, filedName) {
    value = typeof value === 'object' ? value[0] : value
    confirm()
    const tempFilters = JSON.parse(JSON.stringify(filters))
    tempFilters[filedName] = value
    setFilters(tempFilters)
    setCurrentPage(1)
  }

  function handleReset(clearFilters, filedName) {
    clearFilters()
    const tempFilters = JSON.parse(JSON.stringify(filters))
    tempFilters[filedName] = ''
    setFilters(tempFilters)
    setCurrentPage(1)
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          autoFocus={true}
          value={selectedKeys[0]}
          placeholder={`Search ${dataIndex}`}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys[0], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters, dataIndex)}
            size='small'
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
  })

  const getColumnDateProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      const from = `${dataIndex}From`
      const to = `${dataIndex}To`
      let defaultValue = []
      if (!_isEmpty(filters[from])) {
        defaultValue = [moment(filters[from]), moment(filters[to])]
      }
      return (
        <div style={{ padding: 8 }}>
          <RangePicker
            format={'DD-MMM-YYYY'}
            defaultValue={defaultValue}
            allowClear={true}
            onChange={(e) => {
              let tempFilters
              if (e) {
                confirm()
                tempFilters = JSON.parse(JSON.stringify(filters))
                tempFilters[from] = e[0]
                tempFilters[to] = e[1]
              } else {
                clearFilters()
                tempFilters = JSON.parse(JSON.stringify(filters))
                delete tempFilters[from]
                delete tempFilters[to]
              }
              setFilters(tempFilters)
              setCurrentPage(1)
            }}
          />
        </div>
      )
    },
  })

  const columns = [
    {
      title: 'Post Name',
      key: 'post_name',
      render: (_, record) => record?.post_title,
      ...getColumnSearchProps('post_name'),
    },
    {
      title: 'Post Link',
      key: 'guid',
      render: (_, record) =>
        record?.guid ? <Link to={record?.guid}>{record?.guid}</Link> : '-',
    },
    {
      title: 'Created At',
      key: 'createdAt',
      render: (_, record) => moment(record?.post_date).format('DD-MMM-YYYY'),
      ...getColumnDateProps('post_date'),
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => renderAction(record),
    },
  ]

  function handlePagination(page) {
    setSkip((page - 1) * limit)
    setCurrentPage(page)
  }

  function handlePageSizeChange(current, size) {
    setLimit(size)
  }

  return (
    <>
      <CustomMeta title={'Posts'} description='' />
      <PageHeader className='box' title='Posts' />
      <Table
        columns={columns}
        loading={isLoading}
        dataSource={!isLoading && data?.data?.rows}
        pagination={{
          pageSize: limit,
          total: !isLoading && data?.data?.count,
          onChange: handlePagination,
          current: currentPage,
          onShowSizeChange: handlePageSizeChange,
          showSizeChanger: false,
        }}
      />
      {openCreatePostModel && (
        <CreatePostModel
          openCreatePostModel={openCreatePostModel}
          closeModel={closeModel}
          setOpenCreatePostModel={setOpenCreatePostModel}
          feedBackId={feedBackId}
        />
      )}
    </>
  )
}
