import React from 'react'
import { Button, Col, Form, Input, Modal, Row } from 'antd'
import { useQuery, useMutation } from 'react-query'
import { handleDataGet, handleUpdateData } from 'utils/function'
import Page404 from 'components/Page404'
import Loader from 'components/loaders/Loader'

export default function ReplyFeedBackModel({
  openReplyModel,
  feedBackId,
  closeModel,
  setOpenReplyModel,
}) {
  const { data, isLoading, isError } = useQuery('FeedBack', () =>
    handleDataGet(`feedbacks/${feedBackId}`)
  )

  const { isLoading: replyLoading, mutate } = useMutation((data) =>
    handleUpdateData(`reply`, data)
  )

  if (isLoading) return <Loader />
  if (isError) return <Page404 />

  const onFinish = (values) => {
    values = { ...values, id: data?.data?.id }

    mutate(values)
    if (!replyLoading) {
      setOpenReplyModel(false)
    }
  }
  return (
    <Modal
      title='Reply Feed Back'
      visible={openReplyModel}
      onCancel={closeModel}
      footer={null}
    >
      <div>
        <Row gutter={16}>
          <Col className='gutter-row' span={3}></Col>

          <Col className='gutter-row' span={18}>
            <Form
              initialValues={{ reply: data?.data?.reply }}
              onFinish={onFinish}
            >
              <Form.Item
                label='Feed Back Given By User'
                // wrapperCol={{
                //   offset: 8,
                //   span: 16,
                // }}
              >
                {data.data?.feedback}
              </Form.Item>
              <Form.Item
                label='Reply'
                name='reply'
                // wrapperCol={{
                //   offset: 8,
                //   span: 16,
                // }}
              >
                <Input placeholder='Enter Reply' />
              </Form.Item>
              <Form.Item
                wrapperCol={{
                  offset: 8,
                  span: 16,
                }}
              >
                <Button type='primary' htmlType='submit' loading={replyLoading}>
                  Send Reply
                </Button>
              </Form.Item>
            </Form>
          </Col>
          <Col className='gutter-row' span={3}></Col>
        </Row>
      </div>
    </Modal>
  )
}
