import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Button, Form, Input } from 'antd'
import './index.css'
import CustomMeta from 'components/CustomMeta'

const FormItem = Form.Item

export default function () {
  const history = useHistory()
  const [form] = Form.useForm()

  const handleLogin = (values) => {
    if (values.email === 'admin' && values.password === 'Admin@324') {
      localStorage.setItem('token', JSON.stringify(values))
      history.push('/')
    }
  }

  return (
    <div className='gx-app-login-wrap'>
      <CustomMeta title='Login' />
      <div className='gx-app-login-container'>
        <div className='gx-app-login-main-content'>
          <div className='gx-app-logo-content'>
            <div className='gx-app-logo-content-bg'></div>
            <div className='gx-app-logo-wid'>
              <h1 className='login-text-color'>Sign In</h1>
              <p className='login-text-color'>
                By Sign In, you can avail full features of our services.
              </p>
            </div>
            <div className='gx-app-logo'>
              <img alt='' src={''} />
            </div>
          </div>
          <div className='gx-app-login-content'>
            <Form
              form={form}
              className='gx-signin-form gx-form-row0'
              onFinish={handleLogin}
            >
              <FormItem
                name='email'
                rules={[
                  {
                    required: true,
                    message: 'Please input your valid username!',
                  },
                ]}
              >
                <Input placeholder='Email' autoFocus={true} />
              </FormItem>
              <FormItem
                name='password'
                rules={[
                  {
                    required: true,
                    message: 'Please enter your password!',
                    min: 8,
                    max: 16,
                  },
                ]}
              >
                <Input.Password type='password' placeholder='Password' />
              </FormItem>
              <FormItem>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Button type='primary' className='gx-mb-0' htmlType='submit'>
                    Sign In
                  </Button>
                </div>
              </FormItem>
              <span className='forgot-password'>
                <Link to='#'>Forgot Password?</Link>
              </span>
            </Form>
          </div>
        </div>
      </div>
    </div>
  )
}
