import React from 'react'
import { Layout } from 'antd'
import Header_logo from '../assets/images/footer_robot.gif'
import Menu from './Menu'

const { Sider } = Layout

export default function ({ pathname, collapsed }) {
  return (
    <Sider
      className='gx-app-sidebar gx-layout-sider-dark'
      trigger={null}
      collapsible
      collapsed={collapsed}
    >
      <div className={collapsed ? 'logo1' : 'logo'}>
        <img alt='' src={Header_logo} />
      </div>
      <div className='menu-wrapper'>
        <Menu pathname={pathname} />
      </div>
    </Sider>
  )
}
