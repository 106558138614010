import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Users from 'modules/Users'
import Dashboard from 'modules/Dashboard'
import Page404 from 'components/Page404'
import Posts from 'modules/Posts'
import FeedBack from 'modules/FeedBack'

export default function MainRoutes() {
  return (
    <Switch>
      <Route exact path='/' component={Dashboard} />
      <Route exact path='/users' component={Users} />
      <Route exact path='/posts' component={Posts} />
      <Route exact path='/feedBack' component={FeedBack} />
      <Route component={Page404} />
    </Switch>
  )
}
